import React, { Component } from 'react'
import Layout from '../layout'
import { map } from 'lodash'
import CaseStudiesSideMenu from '../case-studies/caseStudiesSideMenu'
import CaseStudiesCategories from '../case-studies/caseStudiesCategories'
//import { TranslatedLink } from '../TranslatedLink'
import { getTranslateFunction, scrollToSlug } from '../../utilities'
import CaseStudiesSideMenuGhost from '../case-studies/caseStudiesSideMenuGhost'
//import LinkBtn from '../linkBtn/LinkBtn'
import PageTitle from '../pageTitle'
import Reveal from '../reveal'
import ContaktUs from '../contactUs/ContactUs'
import './Pages.scss';

export const websitesCategoriesOrder = ['eshop', 'portal', 'corporate', 'microsite', 'crm']

class webPagesTemplate extends Component {

  constructor(props) {

    super(props)
    this.onEnterViewport = this.onEnterViewport.bind(this)
    this.visibleElementChangeHandler = this.visibleElementChangeHandler.bind(this)
    this.state = {
      visibleElement: null,
    }
    this.isInitScrolled = false
  }

  onEnterViewport(id) {
    this.setState({
      visibleElement: id,
    })
  }

  visibleElementChangeHandler(category) {
    this.setState({
      visibleElement: category,
      visibleElementsStack: [],
    })
  }

  componentDidMount() {
    scrollToSlug()
    CaseStudiesSideMenuGhost.setCurrentPage('websites')
  }

  render() {

    const { data, lang } = this.props

    const description = data.common.childMarkdownRemark.frontmatter.description
    const categoriesData = {
      corporate: data.corporate.childMarkdownRemark.frontmatter,
      eshop: data.eshop.childMarkdownRemark.frontmatter,
      microsite: data.microsite.childMarkdownRemark.frontmatter,
      crm: data.crm.childMarkdownRemark.frontmatter,
      portal: data.portal.childMarkdownRemark.frontmatter,
    }

    const categories = map(categoriesData, (category, categoryName) => ({ ...category, categoryName }))

    // let categoriesList = uniq(categories.map((category) => category.categoryName))

    const orderedCategories = websitesCategoriesOrder.map((categoryName) => {
      return categories.find((category) => category.categoryName === categoryName)
    })

    CaseStudiesSideMenuGhost.setActivePage(
      lang,
      'websites',
      websitesCategoriesOrder,
      false,
      this.state.visibleElement,
      this.visibleElementChangeHandler)

    const t = getTranslateFunction(lang)
    return (
      <Layout lang={lang} className={'mobile-menu-offset footer-hide  our-work'}>
        <section className="references project-refs references-groups margin-top">
          <div className="d-md-flex">
            <div className="d-none d-lg-block col-md-3" id="left">
              <CaseStudiesSideMenu
                categoriesList={websitesCategoriesOrder}
                visibleElementChangeHandler={this.visibleElementChangeHandler}
                visibleElement={this.state.visibleElement}
                isGhost={false} />
            </div>
            <div className="col-lg-9 pr-md-0">
              <div className="row subpage-title">
                <div className="col-lg-10">
                  <PageTitle title={t('References.WebpagesTitle')} description={description} />
                  <p>{description}</p>
                  {/*    <div className="col-auto">
                    <LinkBtn>
                      <TranslatedLink to="/en/contact">{t('References.ContactUs')}</TranslatedLink>
                    </LinkBtn>
                  </div> */}
                </div>
              </div>

              <div>
                <CaseStudiesCategories scrollHandler={this.onEnterViewport} categories={orderedCategories} lang={lang} />
              </div>

            </div>
          </div>
        </section>

        <Reveal>
          <ContaktUs
            LeftSideSubTitle={t('References.MoreProjects.Applications')}
            LeftSideLink="/en/our-works/applications"
            linkRight="/en/contact"
            rightSecondLink="/en/brief"
          />
        </Reveal>
      </Layout>
    )
  }
}

export default webPagesTemplate


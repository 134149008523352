import React from 'react'
import WebPagesTemplate from '../../components/pages/web-pagesTemplate'
import { graphql } from 'gatsby'

const webPagesSk = ({ data, ...otherProps }) => (<WebPagesTemplate data={data} lang={'sk'} {...otherProps}/>)


export const query = graphql`{
    common: file(relativePath: {eq: "pages/sk/references/web_pages.md"}) {
        childMarkdownRemark {
            frontmatter {
                description
            }
        }
    }
    corporate: file(relativePath: {eq: "pages/sk/references/web_pages_corporate.md"}) {
        childMarkdownRemark {
            frontmatter {
                case_studies {
                    title
                    description
                    thumbnail_layers {
                        thumbnail {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    background_color
                    case_study
                }
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
    eshop: file(relativePath: {eq: "pages/sk/references/web_pages_eshop.md"}) {
        childMarkdownRemark {
            frontmatter {
                case_studies {
                    title
                    description
                    thumbnail_layers {
                        thumbnail {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    background_color
                    case_study
                }
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
    portal: file(relativePath: {eq: "pages/sk/references/web_pages_portal.md"}) {
        childMarkdownRemark {
            frontmatter {
                case_studies {
                    title
                    description
                    thumbnail_layers {
                        thumbnail {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    background_color
                    case_study
                }
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
    microsite: file(relativePath: {eq: "pages/sk/references/web_pages_microsite.md"}) {
        childMarkdownRemark {
            frontmatter {
                case_studies {
                    title
                    description
                    thumbnail_layers {
                        thumbnail {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    background_color
                    case_study
                }
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
    crm: file(relativePath: {eq: "pages/sk/references/web_pages_crm.md"}) {
        childMarkdownRemark {
            frontmatter {
                case_studies {
                    title
                    description
                    thumbnail_layers {
                        thumbnail {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    background_color
                    case_study
                }
                other_projects {
                    title
                    url_override
                    show_in_references_slider
                    url
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 700, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    case_study
                }
            }
        }
    }
}`

export default webPagesSk
